// import permissions from "../permissions.json";
// import permissions from "../permissions";
// import { getDecodedAccessToken } from "../store/_shared/authAxios";
// import { bvTest } from "../store/_shared/bit_value";
import store from "../store";

export const hasAclAccess = permission => {
  const { auth } = store.getState();

  if (!(auth && auth.details && auth.details.permissions)) {
    alert("user permissions not found in state");
    return false;
  }

  if (!(permission in auth.details.permissions)) {
    // process.env.NODE_ENV !== "production" &&
    //   console.error(`permission '${permission}' not found in defined permissions. Check spelling!`);
    return false;
  }

  return auth.details.permissions[permission] === 1;
};

// export const hasAclAccess = permission => {
//   DEBUGG && console.log(`ACL PERMISSION: `, permission);
//   // check permission exists
//   if (!(permission in permissions)) {
//     // permission not found - always fail

//     // console.error(`permission ${permission} not found in state`, permissions);
//     DEBUGG && console.log(`ERROR: permission ${permission} not found in state`);
//     return false;
//   }

//   // get combined permissions from access token
//   const ut = getDecodedAccessToken();
//   if (!ut) {
//     return false;
//   }

//   // get combined permissions from user access map
//   const ua = JSON.parse(ut.userAccessMap);

//   if (!ua.combined_permissions) {
//     DEBUGG && console.error(`no combined permissions`);
//     return false;
//   }

//   if (!bvTest(ua.combined_permissions, permissions[permission])) {
//     // testing combined permissions against the required permission
//     DEBUGG && console.error(`permission ${permission} not found in user map`);
//     return false;
//   }

//   return true;
// };

export const hasAtLeastOneAclAccess = permissions => {
  if (permissions && permissions.length) {
    for (let i = 0; i < permissions.length; i++) {
      if (hasAclAccess(permissions[i])) return true;
    }
  }
  return false;
};
