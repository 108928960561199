import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { gamesConstants, tenantsConstants } from "../constants";
import { loadedGames } from "../actions/games";

import { buildApiUrl } from "../../utils/api";
import { accessPages, operationsAccess } from "../../utils/users";

const gamesApiUrl = buildApiUrl("/admin/bonuses/selects/games");
const gamesApiUrlPlatform = buildApiUrl("/admin/bonuses/selects/games-platform");

function* loadGamesSaga(action) {
  const opAccess = operationsAccess(accessPages.BONUSES_SELECTS);

  try {
    if (!opAccess.canList) return;

    console.log("[SAGA] loadGamesSaga", action);

    if (action.tenantId) {
      const { data } = yield axios.get(`${gamesApiUrlPlatform}?tenant_id=${action.tenantId}`);
      yield put(loadedGames(data));
    } else {
      const { data } = yield axios.get(`${gamesApiUrl}`);
      yield put(loadedGames(data));
    }
  } catch (e) {
    console.log("[SAGA] loadGamesSaga error", e.toString());
  }
}

export default function* watchGamesSaga() {
  //console.log("[SAGA] watching games");

  yield takeEvery(gamesConstants.LOAD, loadGamesSaga);
  yield takeEvery(tenantsConstants.SET_CURRENT_TENANT, loadGamesSaga);
}
