// ** React Imports
import React, { Fragment, useRef } from "react";
import { User } from "react-feather";
// ** Vertical Menu Components
import VerticalMenuHeader from "./VerticalMenuHeader";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import NestedNav from "./sliding-menu/sliding-menu";
import { withRouter } from "react-router-dom";
import { hasAclAccess, hasAtLeastOneAclAccess } from "../../../../utils/acl";

const rootPath = window.ini.REACT_APP_ROOT_PATH;

const Sidebar = props => {
  const clientId = props.match.params.clientId;
  const siteId = props.match.params.siteId;
  const playerId = props.match.params.playerId;

  // ** Ref
  const shadowRef = useRef(null);

  const iconSize = 18;

  let [menuData] = React.useState({
    id: "side-nav-root",
    isRoot: true,
    items: [
      hasAclAccess("tenant::customer_accounts_player_search_list") && {
        selected: `${rootPath}/players/search`,
        to: `${rootPath}/players/search`,
        primary: "Back",
        icon: <ChevronLeftIcon />,
      },
      {
        category: true,
        primary: "PLAYER PROFILE",
        icon: <User size={iconSize} />,
      },

      hasAclAccess("tenant::customer_accounts_overview_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/overview`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/overview`,
        primary: "Overview",
      },

      hasAclAccess("tenant::customer_accounts_timeline_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/timeline`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/timeline`,
        primary: "Timeline",
      },

      hasAclAccess("tenant::customer_accounts_player_account_management_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/account-management`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/account-management`,
        primary: "Account Management",
      },

      hasAclAccess("tenant::customer_accounts_player_tags_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/tags`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/tags`,
        primary: "Tags",
      },

      hasAclAccess("tenant::customer_accounts_restrictions_limits_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/restrictions-and-limits`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/restrictions-and-limits`,
        primary: "Restrictions and Limits",
      },

      hasAclAccess("tenant::customer_accounts_fraud_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/fraud`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/fraud`,
        primary: "Fraud",
      },

      hasAclAccess("tenant::customer_accounts_player_devices_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/devices`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/devices`,
        primary: "Devices",
      },

      hasAtLeastOneAclAccess([
        "tenant::customer_accounts_games_activity_sportbets_list",
        "tenant::customer_accounts_games_activity_freebets_list",
        "tenant::customer_accounts_games_activity_casino_list",
        "tenant::customer_accounts_games_activity_livecasino_list",
        "tenant::customer_accounts_games_activity_lotto_list",
        "tenant::customer_accounts_games_activity_virtualgames_list",
      ]) && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/games-activity`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/games-activity`,
        primary: "Games Activity",
      },

      hasAclAccess("tenant::customer_accounts_prizes_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/prizes`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/prizes`,
        primary: "Prizes",
      },

      hasAclAccess("tenant::customer_accounts_bonus_search_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/bonus-search`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/bonus-search`,
        primary: "Bonuses",
      },

      hasAclAccess("tenant::financial_in_out_payment_search_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/payment-search`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/payment-search`,
        primary: "Payments",
      },

      hasAclAccess("tenant::customer_accounts_transactions_search_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/transaction-search`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/transaction-search`,
        primary: "Transactions",
      },

      hasAclAccess("tenant::customer_accounts_documents_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/documents-search`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/documents-search`,
        primary: "Documents",
      },

      hasAclAccess("tenant::customer_accounts_cards_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/cards-search`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/cards-search`,
        primary: "Cards",
      },

      hasAclAccess("tenant::customer_accounts_player_comments_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/comments`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/comments`,
        primary: "Comments",
      },

      hasAclAccess("tenant::customer_accounts_wallets_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/wallets`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/wallets`,
        primary: "Wallets",
      },

      hasAclAccess("tenant::customer_accounts_vouchers_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/vouchers`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/vouchers`,
        primary: "Vouchers",
      },

      hasAclAccess("tenant::customer_accounts_loyality_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/loyalty`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/loyalty`,
        primary: "Loyalty" + " (WIP)",
      },

      hasAclAccess("tenant::customer_accounts_inbox_list") && {
        selected: `${rootPath}/player/${clientId}/${siteId}/${playerId}/inbox`,
        to: `${rootPath}/player/${clientId}/${siteId}/${playerId}/inbox`,
        primary: "Inbox" + " (WIP)",
      },
    ],
  });

  return (
    <Fragment>
      {/* Vertical Menu Header */}
      <VerticalMenuHeader {...props} />
      {/* Vertical Menu Header Shadow */}
      <div className="shadow-bottom" ref={shadowRef}></div>
      {/* Perfect Scrollbar */}
      <div className="left-nav-menu">{menuData !== null && <NestedNav menus={menuData} />}</div>
      {/*
      <PerfectScrollbar
        className='main-menu-content'
        options={{ wheelPropagation: false }}
        onScrollY={container => scrollMenu(container)}
      >
        <ul className='navigation navigation-main'>
          <VerticalNavMenuItems
            items={navigation}
            groupActive={groupActive}
            setGroupActive={setGroupActive}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            groupOpen={groupOpen}
            setGroupOpen={setGroupOpen}
            routerProps={routerProps}
            menuCollapsed={menuCollapsed}
            menuHover={menuHover}
            currentActiveItem={currentActiveItem}
          />
        </ul>
      </PerfectScrollbar>
      */}
    </Fragment>
  );
};

export default withRouter(Sidebar);
