import React from "react";
import { ColorPicker } from "material-ui-color";

import { ReactComponent as ROIcon } from "../../../assets/countries/ro.svg";
import { ReactComponent as GBIcon } from "../../../assets/countries/gb.svg";
import AlertCircleFullIcon from "../assets/alert-circle-full";

const numberRegex = /^(-\d*\.\d+|\d*\.\d+|-\d+|\d+)(.*)$/;

const paletteObj = {
  red: "#ff0000",
  blue: "#0000ff",
  green: "#00ff00",
  yellow: "yellow",
  cyan: "cyan",
  lime: "lime",
  gray: "gray",
  orange: "orange",
  purple: "purple",
  black: "black",
  white: "white",
  pink: "pink",
  darkblue: "darkblue",
};

const TextBoxOption = props => {
  const { onChange, invalid } = props;
  const [opened, setOpened] = React.useState(false);

  const [languageData, setLanguageData] = React.useState({
    lang: props.type === "translate" ? "en" : "ro",
    value: { en: "", ro: "" },
  });

  React.useEffect(() => {
    if (props.type === "translate") {
      let initialValue = {
        en: "",
        ro: "",
      };
      if (typeof props.value === "string" && props.hasIcon) {
        initialValue["en"] = props.value;
      } else {
        initialValue = props.value;
      }
      let defLanguage = "en";
      if (props.defLanguage && typeof props.defLanguage === "string") {
        defLanguage = props.defLanguage === "ro" ? props.defLanguage : "en";
      }
      setLanguageData(v => {
        return {
          ...v,
          value: initialValue,
          lang: defLanguage,
        };
      });
    }
  }, []);

  const switchLanguage = () => {
    setLanguageData(v => {
      let newLang = "ro";
      if (v.lang === "ro") newLang = "en";

      return {
        ...v,
        lang: newLang,
      };
    });
  };

  const handleLanguageTextChange = event => {
    (function (eventValue) {
      setLanguageData(v => {
        const newVal = { ...v.value };
        newVal[v.lang] = eventValue;
        setTimeout(() => {
          if (typeof onChange !== "undefined") onChange({ target: { value: newVal, name: props.name } });
        }, 0);

        return {
          ...v,
          value: newVal,
        };
      });
    })(event.target.value);
  };

  const [defaultColor, setDefaultColor] = React.useState("transparent");

  const inputRef = React.useRef(null);
  const incrementRef = React.useRef(null);
  const isDragging = React.useRef(false);
  const dragData = React.useRef({
    clientX: 0,
    clientY: 0,
    value: 0,
    rest: "",
  });

  const onMouseMove = e => {
    if (isDragging.current) {
      let diff = Math.floor((dragData.current.clientY - e.clientY) / 2) * 1 * dragData.current.floatingSanity;

      const newValue = (dragData.current.value + diff) / dragData.current.floatingSanity + dragData.current.rest;

      if (inputRef && inputRef.current) {
        inputRef.current.value = newValue;
      }
    }
  };

  const onMouseUp = () => {
    isDragging.current = false;

    if (inputRef && inputRef.current && inputRef.current.value) {
      onChange({ target: { name: props.name, value: inputRef.current.value } });
    }

    document.body.removeEventListener("mousemove", onMouseMove);
    document.body.removeEventListener("mouseup", onMouseUp);
    dragData.current = {
      clientX: 0,
      clientY: 0,
      value: 0,
      rest: "",
    };
  };

  const onMouseDown = e => {
    console.log(incrementRef.current);
    let text = props.def ? props.def : "";
    if (inputRef.current && inputRef.current.value) {
      text = inputRef.current.value.trim();
    }

    if (!text || !numberRegex.test(text)) {
      return;
    }

    const matches = text.match(numberRegex);
    let value = Number(matches[1]);
    let rest = matches[2];

    let floatingSanity = 1;

    if (matches[1].indexOf(".") > -1) {
      floatingSanity = Math.pow(10, matches[1].split(".")[1].length);
      value *= floatingSanity;
    }

    dragData.current = {
      clientX: e.clientX,
      clientY: e.clientY,
      value: value,
      rest: rest,
      floatingSanity: floatingSanity,
    };

    isDragging.current = true;

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp);
  };

  React.useEffect(() => {
    let ref = null;

    if (!props.type || props.type === "numeric") {
      if (incrementRef.current) {
        ref = incrementRef.current;
        ref.addEventListener("mousedown", onMouseDown);
      }
    }

    return () => {
      if (ref) {
        ref.removeEventListener("mousedown", onMouseDown);
      }
    };
  }, [incrementRef, props.type, props.value]); // eslint-disable-line

  const handleClick = () => {
    setOpened(v => !v);
  };

  const handleDefaultColorChange = val => {
    setDefaultColor(val);
    props.onChange({ target: { name: props.name, value: val.css.backgroundColor } });
  };

  const style = {
    display: "inline-block",
  };

  if (!props.children) {
    style.display = "none";
  }

  const inputType = props.inputType ? props.inputType : "text";
  const type = props.type ? props.type : "numeric";

  let value = props.value ? props.value : props.value !== 0 ? "" : 0;
  if (type === "translate") {
    value = languageData.value[languageData.lang];
  }

  let collapsed = "";
  if (typeof props.collapsable !== "undefined" && !props.collapsable) {
    collapsed = "";
  } else if (typeof props.collapsable !== "undefined" && props.collapsable) {
    if (!opened && !invalid) {
      collapsed = "collapsed";
    }
  } else {
    if (!opened && !invalid) {
      collapsed = "collapsed";
    }
  }

  const autoCompleteProps = {}
  if (typeof props.autoComplete !== "undefined") {
    autoCompleteProps.autoComplete = props.autoComplete;
  }

  return (
    <div
      className={`option textboxoption has-addon ${collapsed} ${type === "translate" ? "has-language" : ""} ${props.className ? props.className : ""
        } ${invalid ? "has-error" : ""}`}
    >
      <label className={"option-content" + (props.inline === true ? " inline" : "")}>
        {!!props.label && <span onClick={handleClick} className={props.value && props.def ? "has-changes" : ""}>
          {props.label}
          <i className="caret" style={style}></i>
        </span>}
        <div className="control">
          {inputType === "textarea" && (
            <textarea
              type={inputType}
              name={props.name}
              value={value}
              onChange={type === "translate" ? handleLanguageTextChange : props.onChange}
              placeholder={typeof props.def !== "undefined" && props.type !== "translate" ? props.def : props.placeholder ? props.placeholder : ""}
              spellCheck="false"
              ref={inputRef}
              className={(props.disabled ? " disabled" : "") + (props.readOnly ? " readOnly" : "")}
              disabled={props.disabled ? true : false}
              readOnly={props.readOnly ? true : false}
              rows={props.rows ? parseInt(props.rows, 10) : 3}
              aria-invalid={invalid}
            />
          )}
          {inputType !== "textarea" && (
            <input
              type={inputType}
              name={props.name}
              value={value}
              onChange={type === "translate" ? handleLanguageTextChange : props.onChange}
              placeholder={typeof props.def !== "undefined" && props.type !== "translate" ? props.def : props.placeholder ? props.placeholder : ""}
              spellCheck="false"
              ref={inputRef}
              className={(props.disabled ? " disabled" : "") + (props.readOnly ? " readOnly" : "") + ("")}
              disabled={props.disabled ? true : false}
              readOnly={props.readOnly ? true : false}
              onKeyDown={typeof props.handleKeyDown !== "undefined" && typeof props.handleKeyDown === "function" ? props.handleKeyDown : null}
              aria-invalid={invalid}
              {...autoCompleteProps}
            />
          )}
          {type === "numeric" && (
            <span className="addon increment-handle" ref={incrementRef}>
              <i></i>
            </span>
          )}
          {type === "color" && (
            <span className="addon color-preview">
              <ColorPicker
                value={props.value ? props.value : "transparent"}
                onChange={handleDefaultColorChange}
                hideTextfield
                palette={paletteObj}
              />
            </span>
          )}
          {type === "translate" && (
            <React.Fragment>
              <span
                className={`addon lang first " ${languageData.lang !== "ro" ? "disabled" : ""} ${languageData.value.ro ? "has-value" : ""}`}
                title="Romanian"
                onClick={switchLanguage}
              >
                <ROIcon />
                <div className="check"></div>
              </span>
              <span
                className={`addon lang " ${languageData.lang !== "en" ? "disabled" : ""} ${languageData.value.en ? "has-value" : ""}`}
                title="English"
                onClick={switchLanguage}
              >
                <GBIcon />
                <div className="check"></div>
              </span>
            </React.Fragment>
          )}

          {type === "string" && props.adornment && (
            <React.Fragment>
              <span className={`addon`}>
                <i onClick={props.onAdornmentClick}>{props.adornment}</i>
              </span>
            </React.Fragment>
          )}
          {invalid && (<div className="error-indicator">
            <AlertCircleFullIcon size={16} />
          </div>)}
        </div>
      </label>
      <div className={"children"}>
        {props.children}
        {invalid && typeof props.errorMessages !== "undefined" && props.errorMessages.length > 0 && (
          <div className="error">
            {props.errorMessages.map((e, idx) => {
              return <React.Fragment key={`tenant-name-error-${idx}`}>
                <span>{e}.</span><br />
              </React.Fragment>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextBoxOption;
