import { produce } from "immer";
import { gamesConstants } from "../constants";

const initialState = {
  game_providers: [],
  games: []
};

const gamesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case gamesConstants.LOADED: {

        if (typeof action.data.game_providers !== "undefined") draft.game_providers = action.data.game_providers;
        if (typeof action.data.games !== "undefined") draft.games = action.data.games;

        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default gamesReducer;